import React from "react";
import HeroImage from "../assets/heroImage.png";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

const Home = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          <span className="mr-2">LinkedIn</span> <FaLinkedin size={30} />
        </>
      ),
      href: "https://www.linkedin.com/in/edgaralcocer/",
      style: "rounded-tr-md"
    },
    {
      id: 2,
      child: (
        <>
          <span className="mr-2">Github</span> <FaGithub size={30} />
        </>
      ),
      href: "https://github.com/edgui13"
    },
    {
      id: 3,
      child: (
        <>
          <span className="mr-2">Email</span> <HiOutlineMail size={30} />
        </>
      ),
      href: "mailto:edgaralcas13@gmail.com"
    },
    {
      id: 4,
      child: (
        <>
          <span className="mr-2">Resume</span>{" "}
          <BsFillPersonLinesFill size={30} />
        </>
      ),
      href: "/Edgar_Alcocer_Resume.pdf",
      style: "rounded-br-md",
      download: true
    }
  ];

  return (
    <div
      name="home"
      className="pt-36 bg-gradient-to-b from-black via-black to-gray-800 flex items-center justify-center "
    >
      <div className="max-w-screen-lg max-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className=" font-handjet text-4xl sm:text-7xl font-bold text-white">
            I'm a Full-Stack Developer
          </h2>
          <p className="text-gray-500 py-4 ">
            I have 3 years of experience building and designing software. Some
            industry standard tech I work with are React, Java and Python.
          </p>
          <div className="grid grid-cols-3 gap-6 justify-items-center items-center">
            {links.slice(0, 3).map(({ id, child, href, style, download }) => (
              <a
                key={id}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-white bg-gradient-to-b from-cyan-500 to-blue-500 w-full px-6 py-3 mb-2 flex items-center justify-center rounded-md  hover:scale-110 duration-300 cursor-pointer ${style}`}
                download={download}
              >
                {child}
              </a>
            ))}
            <a
              href={links[3].href}
              target="_blank"
              rel="noopener noreferrer"
              className={`col-start-2 text-white bg-gradient-to-b from-cyan-500 to-blue-500 w-full px-6 py-3 mb-2 flex items-center justify-center rounded-md  hover:scale-110 duration-300 cursor-pointer ${links[3].style}`}
              download={links[3].download}
            >
              {links[3].child}
            </a>
          </div>
        </div>

        <div className="ml-8 ">
          <img
            src={HeroImage}
            alt="my profile"
            className="rounded-2xl mx-auto w-2/3 md:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
