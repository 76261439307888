import React from "react";
import html from "../assets/experience/html.png";
import css from "../assets/experience/css.png";
import javascript from "../assets/experience/javascript.svg";
import cpp from "../assets/experience/cpp.svg";
import reactImg from "../assets/experience/reactImg.png";
import java from "../assets/experience/java.svg";
import python from "../assets/experience/python.svg";
import tailwind from "../assets/experience/tailwind.png";
import cs from "../assets/experience/cs.svg";
import github from "../assets/experience/github.png";
import git from "../assets/experience/git.png";
import powerBI from "../assets/experience/powerBI.png";

const Experience = () => {
  const tech = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-orange-500"
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "shadow-blue-500"
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500"
    },
    {
      id: 4,
      src: cpp,
      title: "C++",
      style: "shadow-blue-500"
    },
    {
      id: 5,
      src: reactImg,
      title: "React",
      style: "shadow-sky-500"
    },
    {
      id: 6,
      src: java,
      title: "Java",
      style: "shadow-orange-500"
    },
    {
      id: 7,
      src: python,
      title: "Python",
      style: "shadow-yellow-500"
    },
    {
      id: 8,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-500"
    },
    {
      id: 9,
      src: cs,
      title: "C#",
      style: "shadow-purple-500"
    },
    {
      id: 10,
      src: github,
      title: "Github",
      style: "shadow-gray-500"
    },
    {
      id: 11,
      src: git,
      title: "Git",
      style: "shadow-orange-500"
    },
    {
      id: 13,
      src: powerBI,
      title: "PowerBI",
      style: "shadow-yellow-500"
    }
  ];
  return (
    <div
      name="experience-component"
      className="pt-80 bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p
            id="experience"
            className="font-handjet md:text-6xl  text-4xl font-bold inline border-b-4 border-gray-500"
          >
            Experience
          </p>
          <p className="pt-6">These are the technologies I've worked with</p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {tech.map(({ id, src, style }) => (
            <div
              key={id}
              className={`flex justify-center items-center shadow-md hover:scale-110 duration-500 py-2 rounded-lg ${style} w-50 h-50`}
            >
              <img src={src} alt="" className="w-24 " />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
