import React from "react";

function Contact() {
  return (
    <div
      name="contact-component"
      className="pt-80 pb-24 bg-gradient-to-b from-black to-gray-800 p-4 text-white "
    >
      <div className="max-w-screen-lg  mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p
            id="contact"
            className="font-handjet md:text-6xl  text-4xl font-bold inline border-b-4 border-gray-500"
          >
            Contact
          </p>
          <p className="py-6">Submit the form below to get in touch with me</p>
        </div>

        <div className="flex justify-center items-center">
          <form
            // onSubmit={handleSubmit}
            action="https://getform.io/f/6bbff10d-2263-45e4-8786-ce3f3977063b"
            method="POST"
            className="flex flex-col w-full md:w-1/2"
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              //   value={name}
              //   onChange={(e) => setName(e.target.value)}
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              //   value={email}
              //   onChange={(e) => setEmail(e.target.value)}
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea
              type="text"
              name="message"
              placeholder="Enter your message"
              //   value={message}
              //   onChange={(e) => setMessage(e.target.value)}
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            ></textarea>
            <button
              type="submit"
              className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mt-2 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
            >
              Let's Talk!
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
