import React from "react";
import ECOfficient from "../assets/portfolio/ECOfficient.png";
import LegendOfZelda from "../assets/portfolio/LegendOfZelda.png";
import ComingSoon from "../assets/portfolio/ComingSoon.png";
import OnYourFeet from "../assets/portfolio/OnYourFeet.png";
import Technopedia from "../assets/portfolio/Technopedia.png";
import WebDev from "../assets/portfolio/WebDev.png";

const Portfolio = () => {
  const portfolio = [
    {
      id: 1,
      src: ECOfficient,
      title: "ECOfficient",
      website: "https://ecofficient.netlify.app/",
      github: "https://github.com/edgui13/Legend-Of-Zelda-JavaScript",
      technologies: ["HTML", "CSS", "Javascript"],
      description:
        "Ecofficient optimizes routes with weather data to promote eco-friendly travel"
    },
    {
      id: 2,
      src: LegendOfZelda,
      title: "Legend Of Zelda",
      website: "https://legendofzeldarecreation.netlify.app/",
      github: "https://github.com/edgui13/ECOfficient",
      technologies: ["HTML", "CSS", "Javascript", "Java", "Python"],
      description: "A recreation of the classic video game Legend of Zelda"
    },
    {
      id: 3,
      src: OnYourFeet,
      title: "Clinic App",
      website: "",
      github: "",
      technologies: [".NET", "C#"],
      description:
        "Clinical management app perform CRUD operations on patient data stored"
    },
    {
      id: 4,
      src: Technopedia,
      title: "Technopedia AI",
      website: "",
      github: "",
      technologies: ["Python, PowerBI"],
      description:
        "AI-powered tool to catalog and analyze company software, preventing duplicate license purchases"
    },
    {
      id: 5,
      src: WebDev,
      title: "General WebDev",
      website: "https://edguiexample.netlify.app/",
      github: "https://github.com/edgui13/Edg.ui-Website",
      technologies: ["React"],
      description:
        "Designing, coding, and modifying websites to create a user-friendly and functional online experience for visitors"
    },
    {
      id: 6,
      src: ComingSoon,
      title: "Coming Soon",
      website: "",
      github: "",
      technologies: [""],
      description: "Something's Cooking"
    }
    // {
    //   id: 7,
    //   src: ComingSoon,
    //   title: "Coming Soon",
    //   website: ""
    // }
  ];
  return (
    <div
      name="portfolio-component"
      className="pt-80  bg-gradient-to-b from-black to-gray-800 text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p
            id="portfolio"
            className="font-handjet md:text-6xl  text-4xl font-bold inline border-b-4 border-gray-500"
          >
            Portfolio
          </p>
          <p className="py-6">Check out some of my work</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {portfolio.map(
            ({
              id,
              src,
              title,
              description,
              website,
              github,
              technologies
            }) => (
              <div
                key={id}
                className="relative flex flex-col shadow-md shadow-gray-600 rounded-lg mb-4"
              >
                <button
                  onClick={() => window.open(website, "_blank")}
                  className="flex-grow overflow-hidden rounded-t-md relative transform transition-transform duration-200 hover:scale-105"
                >
                  <img
                    src={src}
                    alt=""
                    className="transform transition-transform duration-200 hover:scale-105 w-full h-full"
                    // className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-200 p-4">
                    <p className="mb-2 text-white">{description}</p>
                    <p className="text-white">{technologies.join(", ")}</p>
                  </div>
                </button>
                <a
                  href={github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" w-full flex items-center justify-center bg-gray-800 hover:bg-gray-700 transition-colors duration-200 rounded-b-md py-2 "
                >
                  <span className="text-white transition-transform hover:scale-110">
                    {title}
                  </span>
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
