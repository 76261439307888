import React from "react";

const About = () => {
  return (
    <div
      name="about-component"
      className="pt-80 bg-gradient-to-b from-gray-800 to-black text-white "
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p
            id="about"
            className="font-handjet md:text-6xl  text-4xl font-bold inline border-b-4 border-gray-500"
          >
            About
          </p>
        </div>
        <p className="text-l">
          I am originally from Maryland, where I spent my childhood. But at the
          age of 10, I moved to Cochabamba, Bolivia. The rich culture and warm
          people helped guide me through my formative years. Those years, filled
          with diverse experiences, helped me become more adaptable and develop
          a wider viewpoint of different perspectives.
        </p>
        <br />
        <p className="text-l">
          Now, I find myself in Fayetteville, Arkansas, I'm currently finishing
          my undergraduate degree at the University of Arkansas where I will be
          double majoring in computer science in the College of Engineering and
          information systems at The Walton College of Business. I'm looking for
          opportunities that will help me build a strong career path.
        </p>
        <br />
        <p className="text-l">
          Outside of tech, I like to spend time with friends and family. In a
          constantly changing world cherishing time with those around you has
          never been more crucial. My personal escapes include cooking, working
          out, stock market investing, and video games.
        </p>
      </div>
    </div>
  );
};

export default About;
